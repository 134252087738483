import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Navbar, Footer } from 'modules/app';
import * as styles from './styles/Root.styles';
import {
  CompanyHeader,
  Discover,
  EngageTalent,
  FutureTalent,
  ManageTalent,
} from './components';
import { WhyRoangoSection } from 'modules/shared';
import { whyRoangoSectionData } from './const/whyRoangoSectionData';

export const CompaniesPage: React.FC<RouteComponentProps> = () => {
  return (
    <article css={styles.root}>
      <Navbar isGray />
      <CompanyHeader />
      <Discover />
      <ManageTalent />
      <EngageTalent />
      <FutureTalent />
      <WhyRoangoSection {...whyRoangoSectionData} />
      <Footer />
    </article>
  );
};
