import { motion } from 'framer-motion';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import { Paragraph } from 'modules/app';
import React from 'react';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';

import * as styles from '../styles/Header.styles';

export const CompanyHeader: React.FC = () => {
  return (
    <header css={styles.root}>
      <motion.h1
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 0.5,
          type: 'keyframes',
          ease: 'easeInOut',
        }}
        css={styles.title}
      >
        Premium Access to Ever-Growing Roango Talent Pool.{' '}
        <span>1000s of Talents Waiting to Be Discovered.</span>
      </motion.h1>
      <motion.div
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 0.5,
          delay: 0.4,
          type: 'keyframes',
          ease: 'easeInOut',
        }}
        css={styles.signUp}
      >
        <Link to="/register" css={styles.link}>
          <span>
            <FormattedMessage id="buttons.joinRoango" />
          </span>
        </Link>
        <Paragraph size="fluidMedium" fontFamily="primary" css={styles.details}>
          <span>
            <FormattedMessage id="homepage.header.free" />{' '}
          </span>
          <FormattedMessage id="homepage.header.for_talents" />
        </Paragraph>
      </motion.div>
    </header>
  );
};
