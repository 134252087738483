import { css } from '@emotion/react';

export const root = css`
  min-height: 100vh;
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
`;

export const wrapper = css`
  background: hsl(var(--color-white-11));
  box-sizing: content-box;
`;
