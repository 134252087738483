import { motion } from 'framer-motion';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
  STAGGER_CONTAINER_AND_OPACITY,
} from 'style/animations.config';
import { discoverListData } from '../const/discoverListData';
import * as styles from '../styles/Discover.styles';
import { DiscoverListItem } from './DiscoverListItem';
import Search from 'assets/icons/discover-list-search.svg';
import Add from 'assets/icons/discover-list-add.svg';
import Star from 'assets/icons/discover-list-star.svg';
import { useOnScrollAnimation } from 'modules/app';

export const FutureTalent: React.FC = () => {
  const textContainer = useOnScrollAnimation({ threshold: 0.1 });
  const listItem = useOnScrollAnimation({ threshold: 0.3 });

  return (
    <motion.section
      initial="hidden"
      animate={textContainer.animation}
      transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
      variants={STAGGER_CONTAINER}
      ref={textContainer.ref}
      css={styles.root}
    >
      <motion.h1
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={[styles.title, styles.marginNone]}
      >
        Discover the Future of Talent Acquisition
      </motion.h1>
      <motion.p
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.descriptionSecondary}
      >
        Roango's Key Features for Streamlined Hiring
      </motion.p>
      <ul css={styles.discoverList}>
        <motion.li
          initial="hidden"
          animate={listItem.animation}
          transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
          variants={STAGGER_CONTAINER_AND_OPACITY}
          ref={listItem.ref}
          css={styles.discoverListItem}
        >
          <motion.div
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.discoverListItemNumber}
          >
            01
          </motion.div>
          <motion.div
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.discoverListItemTextWrapper}
          >
            <p css={styles.discoverListItemTitle}>Growing Talent Pool</p>
            <p css={styles.discoverListItemDescription}>
              Source, Engage and Manage
            </p>
          </motion.div>
          <div css={styles.discoverListIconWrapperPrimary}>
            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={{
                duration: 0.5,
                type: 'keyframes',
                ease: 'easeInOut',
              }}
              css={styles.discoverListIcon}
            >
              <Search />
            </motion.div>
            <p css={styles.descriptionTertiary}>
              <span>Pick and choose</span> talent based on skill and work type
            </p>
          </div>
          <motion.div
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.discoverListIconWrapperSecondary}
          >
            <div css={styles.discoverListIcon}>
              <Add />
            </div>
            <p css={styles.descriptionTertiary}>
              <span>Invite or accept</span> talent to your job openings
            </p>
          </motion.div>
          <motion.div
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.discoverListIconWrapperTertiary}
          >
            <div css={styles.discoverListIcon}>
              <Star />
            </div>
            <p css={styles.descriptionTertiary}>
              <span>Screen and review</span> profiles, cover letters and assign
              evaluation points with notes.
            </p>
          </motion.div>
        </motion.li>
        {discoverListData.map((item, i) => (
          <DiscoverListItem key={i} {...item} />
        ))}
      </ul>
    </motion.section>
  );
};
