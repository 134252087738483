import { css } from '@emotion/react';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const base = css`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 40px;
  border-top: 1px solid hsl(var(--color-borderDark-7));

  @media ${breakpoints.medium} {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 40px;
  }
`;

export const textWrapper = css`
  margin-bottom: 20px;

  @media ${breakpoints.medium} {
    grid-column: 2 / span 3;
    margin-bottom: 0;
  }
`;

export const number = css`
  ${font.size.tiny};
  ${font.weight.bold}
  color: hsl(var(--color-textLight-7));
  margin-bottom: 12px;

  @media ${breakpoints.medium} {
    grid-column: 1 / span 1;
    margin-bottom: 0;
  }
`;

export const title = css`
  ${font.size.medium};
  ${font.weight.bold}
`;

export const description = css`
  ${font.size.medium};
  color: hsl(var(--color-textLight-7));
`;

export const list = css`
  padding: 0;
  ${font.size.base};
  color: hsl(var(--color-textLight-7));
  list-style: disc;
  list-style-position: inside;

  @media ${breakpoints.medium} {
    grid-column: 6 / span 6;
  }
`;
