import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageOverlay, useOnScrollAnimation } from 'modules/app';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import * as styles from '../styles/Discover.styles';

export const EngageTalent: React.FC = () => {
  const textContainer = useOnScrollAnimation({ threshold: 0.1 });
  const list = useOnScrollAnimation({ threshold: 0.3 });

  return (
    <motion.section
      initial="hidden"
      animate={textContainer.animation}
      transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
      variants={STAGGER_CONTAINER}
      ref={textContainer.ref}
      css={styles.root}
    >
      <motion.h2
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.subTitle}
      >
        Engage Talent
      </motion.h2>
      <motion.p
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.descriptionSecondary}
      >
        Message candidates and track activities.Review CVs, send e-mails and
        organize using tags.
      </motion.p>
      <div css={styles.imageWrapperSecondary}>
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          css={styles.image}
          src="../../../assets/images/roango-engage-talent.jpg"
          alt=""
          quality={100}
        />
        <ImageOverlay
          threshold={0.3}
          type="top"
          background="hsl(var(--color-grayscale-9))"
        />
      </div>
      <motion.ul
        initial="hidden"
        animate={list.animation}
        transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
        variants={STAGGER_CONTAINER}
        ref={list.ref}
        css={styles.list}
      >
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Tag management</p>
          <p css={styles.listItemDescription}>
            Add existing or create new tags for job applications.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Stage management..</p>
          <p css={styles.listItemDescription}>
            Tailor your pipelines with reasons for stage change.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Direct Messaging</p>
          <p css={styles.listItemDescription}>
            Communicate directly and within departments.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Talent Evaluation</p>
          <p css={styles.listItemDescription}>
            Assign points and add additional notes.
          </p>
        </motion.li>
      </motion.ul>
    </motion.section>
  );
};
