export const discoverListData = [
  {
    index: 2,
    title: 'Customizable Company Profile',
    description: 'Customizable and Shareable',
    list: [
      'Pitch your company to talents',
      'List your job openings',
      'Share it anywhere',
    ],
  },
  {
    index: 3,
    title: 'Job Openings Listing',
    description: 'Internal and Public',
    list: [
      'By department and location',
      'Schedule publishing',
      'Share it anywhere',
    ],
  },
  {
    index: 4,
    title: 'Customizable Company Profile',
    description: 'Customizable and Shareable',
    list: [
      'Manage job opening applications',
      'Make use of custom pipelines',
      'Create, assign and manage stage reasons',
    ],
  },
  {
    index: 5,
    title: 'Direct Messaging',
    description: 'Communicate Directly',
    list: [
      'Communicate via internal messaging',
      'Stay in the loop via email notifications',
      'Per department messaging',
    ],
  },
  {
    index: 6,
    title: 'Top Notch Support',
    description: 'Guidance & Assistance',
    list: [
      'Onboarding support',
      'Company profile and job openings setup',
      'Data migration and assistance',
    ],
  },
];
