export const whyRoangoSectionData = {
  title: 'Stop the Resume Harvest! Find The Right Fit, Fast.',
  titleSpecial: 'Start Inviting Talent Now!',
  listDescriptions: [
    'Source Talent from a single pool. Intelligent matchmaking saves your time and effort.',
    'Keep track of the entire hiring process with the built-in ATS and messaging.',
    'Augment your employer branding with a customizable company profile to attract the best fit.',
  ],
  description:
    'Pick & Choose. Screen & Review. Invite & Accept. Leverage Roango talent pool and built-in ATS to your advantage.',
};
