import React from 'react';
import Seo from 'modules/app/components/Seo';
import { CompaniesPage } from 'modules/companies';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const Companies: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Companies - Roango"
        description="Onboard new, skilled talents that fit your company culture. Advertise your job opening, manage your hiring process and scale up fast."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <CompaniesPage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default Companies;
