import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageOverlay, useOnScrollAnimation } from 'modules/app';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import * as styles from '../styles/Discover.styles';

export const Discover: React.FC = () => {
  const title = useOnScrollAnimation({ threshold: 0.3 });
  const descrition = useOnScrollAnimation({ threshold: 0.3 });
  const list = useOnScrollAnimation({ threshold: 0.3 });

  return (
    <section css={styles.root}>
      <motion.h2
        initial="hidden"
        animate={title.animation}
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        ref={title.ref}
        css={styles.title}
      >
        Discover & Source Talent
      </motion.h2>
      <div css={styles.imageWrapperPrimary}>
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          css={styles.image}
          src="../../../assets/images/roango-discover-source-talent.jpg"
          alt=""
          quality={100}
        />
        <ImageOverlay
          threshold={0.3}
          type="top"
          background="hsl(var(--color-grayscale-9))"
        />
      </div>
      <div css={styles.imageWrapperMobile}>
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          css={styles.image}
          src="../../../assets/images/roango-discover-source-talent.jpg"
          alt=""
          quality={100}
        />
        <ImageOverlay
          threshold={0.3}
          type="top"
          background="hsl(var(--color-grayscale-9))"
        />
      </div>
      <motion.p
        initial="hidden"
        animate={descrition.animation}
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        ref={descrition.ref}
        css={styles.descriptionPrimary}
      >
        Transform your talent strategy with Roango Talent pool. Streamline your
        hiring process and source high-potential candidates with ease.
      </motion.p>
      <motion.ul
        initial="hidden"
        animate={list.animation}
        transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
        variants={STAGGER_CONTAINER}
        ref={list.ref}
        css={styles.list}
      >
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Pick & Invite talent.</p>
          <p css={styles.listItemDescription}>
            Select matched talent and invite them to the pipeline.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Talent Info.</p>
          <p css={styles.listItemDescription}>
            Relevant skills and expertise info for each matched talent.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Ordered by relevancy.</p>
          <p css={styles.listItemDescription}>
            From top to bottom, the most relevant talent is shown.
          </p>
        </motion.li>
      </motion.ul>
    </section>
  );
};
