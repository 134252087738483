import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageOverlay, useOnScrollAnimation } from 'modules/app';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';
import * as styles from '../styles/Discover.styles';

export const ManageTalent: React.FC = () => {
  const textContainer = useOnScrollAnimation({ threshold: 0.1 });
  const list = useOnScrollAnimation({ threshold: 0.3 });

  return (
    <motion.section
      initial="hidden"
      animate={textContainer.animation}
      transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
      variants={STAGGER_CONTAINER}
      ref={textContainer.ref}
      css={styles.root}
    >
      <motion.h2
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.subTitle}
      >
        Manage Talent
      </motion.h2>
      <motion.p
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.descriptionSecondary}
      >
        Applicant Tracking System enables easy team collaboration with progress
        tracking and unlimited custom pipelines.{' '}
      </motion.p>
      <div css={styles.talentImageWrapperTertiary}>
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          css={styles.image}
          src="../../../assets/images/roango-manage-talent.jpg"
          alt=""
          quality={100}
        />
        <ImageOverlay
          threshold={0.3}
          type="top"
          background="hsl(var(--color-grayscale-9))"
        />
      </div>
      <div css={styles.imageWrapperMobile}>
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          css={styles.image}
          src="../../../assets/images/roango-manage-talent-mobile.jpg"
          alt=""
          quality={100}
        />
        <ImageOverlay
          threshold={0.3}
          type="top"
          background="hsl(var(--color-grayscale-9))"
        />
      </div>
      <motion.ul
        initial="hidden"
        animate={list.animation}
        transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
        variants={STAGGER_CONTAINER}
        ref={list.ref}
        css={styles.list}
      >
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Pipeline management.</p>
          <p css={styles.listItemDescription}>
            Create, manage and customize unlmited pipelines.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Stage reasons management.</p>
          <p css={styles.listItemDescription}>
            Create, manage and customize your stage reasons.
          </p>
        </motion.li>
        <motion.li
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.listItem}
        >
          <p css={styles.listItemTitle}>Pipeline notifications.</p>
          <p css={styles.listItemDescription}>
            Get notified on changes within the pipelines.
          </p>
        </motion.li>
      </motion.ul>
    </motion.section>
  );
};
