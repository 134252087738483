import { motion } from 'framer-motion';
import { useOnScrollAnimation } from 'modules/app';
import React from 'react';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER_AND_OPACITY,
} from 'style/animations.config';
import * as styles from '../styles/DiscoverListItem.styles';

interface Props {
  index: number;
  title: string;
  description: string;
  list: string[];
}

export const DiscoverListItem: React.FC<Props> = ({
  index,
  title,
  description,
  list,
}) => {
  const container = useOnScrollAnimation({ threshold: 0.5 });

  return (
    <motion.li
      initial="hidden"
      animate={container.animation}
      transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
      variants={STAGGER_CONTAINER_AND_OPACITY}
      ref={container.ref}
      css={styles.base}
    >
      <motion.div
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.number}
      >
        0{index}
      </motion.div>
      <motion.div
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.textWrapper}
      >
        <p css={styles.title}>{title}</p>
        <p css={styles.description}>{description}</p>
      </motion.div>
      <motion.ul
        variants={FADE_IN_MOVE_Y_CONTROLLED}
        transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
        css={styles.list}
      >
        {list.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </motion.ul>
    </motion.li>
  );
};
