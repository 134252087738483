import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { primary } from 'modules/app/styles/CtaButton.styles';
import { config } from 'style/grids.config';
import { breakpoints } from 'style/variables';

export const root = css`
  width: 100%;
  background: hsl(var(--color-grayscale-9));
  margin-top: 120px;
  margin-bottom: 120px;
  padding: 0 20px;

  @media ${breakpoints.medium} {
    ${config.base};
    margin-top: 120px;
    margin-bottom: 240px;
  }
`;

export const title = css`
  grid-column: 2 / span 10;
  ${font.family.secondary};
  font-size: clamp(2.25rem, 1.875rem + 1.875vw, 3.75rem);
  line-height: clamp(2.5rem, 2.125rem + 1.875vw, 4rem);
  font-weight: normal;
  letter-spacing: -1px;
  margin-bottom: 20px;

  span {
    color: hsl(var(--color-warning-9));
  }

  @media ${breakpoints.medium} {
    grid-column: 2 / span 10;
  }
`;

export const signUp = css`
  grid-column: 2 / span 10;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

export const link = css`
  ${primary};
  background-color: hsl(var(--color-warning-9));
  color: hsl(var(--color-text-1));

  &::before {
    background: hsl(var(--color-robin-2));
  }

  &:hover,
  &:active {
    color: hsl(var(--color-text-1));
  }
`;

export const details = css`
  font-size: 14px;
  line-height: calc(var(--unit) * 5);

  span {
    ${font.weight.bold}
  }
`;
